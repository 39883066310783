import React, {
  useContext,
  useEffect,
  useMemo,
  useCallback,
  createContext,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import Script from 'next/script'
import { useRouter } from 'next/router'

import { sendError } from '@/utils/rollbar'
import usePathnameChange from '@/hooks/usePathnameChange'
import usePrevious from '@/hooks/usePrevious'

const DriftContext = createContext(null)

export const useDrift = () => useContext(DriftContext)

const DriftProvider = ({ children }) => {
  const [isDriftReady, setDriftReady] = useState(false)
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const { pathname } = useRouter()
  const prevPathname = usePrevious(pathname)

  const openChat = useCallback((interactionId, options) => {
    window.drift.api.startInteraction({ interactionId, ...options })
  }, [])

  const loadPlaybook = () => {
    let playbookId = 317816

    if (pathname === '/migration-support') {
      playbookId = 362364
    }
    setDriftReady(true)
    openChat(playbookId, { goToConversation: false })
  }

  usePathnameChange(() => {
    if (pathname === '/migration-support' || prevPathname === '/migration-support') {
      loadPlaybook()
    }
  })

  const initializeDrift = () => {
    if (!window.drift) {
      sendError(null, 'window.drift not available in drift.load')
      return
    }

    window.drift.load('zxs2h5fz37vs')
    window.drift.on('ready', loadPlaybook)
  }

  const providerValue = useMemo(() => ({
    isDriftReady,
    openChat,
  }), [isDriftReady, openChat])

  useEffect(() => {
    if (scriptLoaded) {
      initializeDrift()
    }
  }, [scriptLoaded])

  return (
    <DriftContext.Provider value={providerValue}>
      <>
        {children}
        <Script onLoad={() => setScriptLoaded(true)} src="https://js.driftt.com/include/1683150600000/zxs2h5fz37vs.js" strategy="lazyOnload" />
      </>
    </DriftContext.Provider>
  )
}

DriftProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DriftProvider
