import React, { useCallback, useState, useContext, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { useAlerts, userAgent } from '@theneatcompany/nui'

import { sendError } from '@/utils/rollbar'
import { DEFAULT_PLAN_SKU } from '@/constants'

const SignUpContext = React.createContext()

export const useSignUp = () => useContext(SignUpContext)

const STEPS = {
  authenticate: 'authenticate',
  selectPlan: 'selectPlan',
  existingUser: 'existingUser',
  resetPassword: 'resetPassword',
  newUser: 'newUser',
  billing: 'billing',
}

const SignUpProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [formValues, setFormValues] = useState({})
  const [navigation, setNavigation] = useState({ steps: STEPS, currentStep: STEPS.authenticate })
  const [plan, setPlan] = useState({})
  const [coupon, setCoupon] = useState({})
  const [canPurchasePlan, setCanPurchasePlan] = useState(0)
  const [isLinkedToGoogle, setIsLinkedToGoogle] = useState(false)
  const [formHolderHeight, setFormHolderHeight] = useState(0)
  const [formBoxHeight, setFormBoxHeight] = useState(0)
  const [order, setOrder] = useState({})
  const [csrfToken, setCsrfToken] = useState({})
  const [utm, setUtm] = useState({})

  const alerts = useAlerts()

  const {
    query: {
      utm_source: utmSource = false,
      utm_medium: utmMedium = false,
      utm_campaign: utmCampaign = false,
      sku = '',
      couponCode = '',
    },
    isReady,
  } = useRouter()
  const navigateToStep = (step) => {
    setNavigation({ ...navigation, ...{ currentStep: navigation.steps[step] } })
  }

  const shouldSkipPlanSelect = useMemo(() => !!(plan.isTrial || plan.sku === 'vault50' || plan.sku?.startsWith('neathome')), [plan])

  const bannerDescription = useMemo(() => coupon?.utmCampaigns?.find(
    campaign => campaign.name === utm?.campaign?.toLowerCase(),
  )?.description ?? null, [utm, coupon])

  useEffect(() => {
    const fetchCouponByCampaign = async (campaign) => {
      const res = await fetch('/api/signup/coupon/campaign', {
        method: 'POST',
        body: JSON.stringify({ campaign }),
      })

      const campaignCoupon = await res.json()
      if (res.status && res.status > 300) {
        alerts.error('Error Fetching Coupon')
        return null
      }

      setCoupon(campaignCoupon)
      setFormValues(prevState => ({ ...prevState, promoCode: campaignCoupon.code }))
      return campaignCoupon
    }

    if (utmSource || utmMedium || utmCampaign) {
      setUtm({
        source: utmSource,
        medium: utmMedium,
        campaign: utmCampaign,
      })
    }

    if (utmCampaign) {
      fetchCouponByCampaign(utmCampaign)
    }
  }, [utmSource, utmMedium, utmCampaign])

  useEffect(() => {
    const fetchCoupon = async (promoCode) => {
      const res = await fetch('/api/signup/coupon', {
        method: 'POST',
        body: JSON.stringify({ promoCode }),
      })

      const couponRes = await res.json()
      if (res.status && res.status > 300) {
        alerts.error('Error Fetching Coupon')
        return null
      }

      setCoupon(couponRes)
      setFormValues(prevState => ({ ...prevState, promoCode: couponCode }))
      return couponRes
    }

    if (couponCode) {
      try {
        fetchCoupon(couponCode)
      } catch (err) {
        alerts.error('Error Fetching Coupon')
        sendError(err, 'Error fetching /api/signup/coupon')
      }
    }
  }, [couponCode])

  useEffect(() => {
    if (!isReady) return
    setIsLoading(true)

    const fetchPlan = async (planLookup) => {
      const res = await fetch('/api/signup/plan', {
        method: 'POST',
        body: JSON.stringify({ plan: planLookup }),
      })

      const planRes = await res.json()

      if (res.status && res.status > 300) {
        alerts.error('Error Fetching Plan')
        return null
      }
      setPlan(planRes)

      if (planRes.isTrial) navigateToStep(navigation.steps.authenticate)

      return planRes
    }

    setCsrfToken('')

    let planSku = DEFAULT_PLAN_SKU
    if (sku) {
      planSku = sku
    }

    try {
      fetchPlan(planSku)
    } catch (err) {
      alerts.error('Error Fetching Plan')
      sendError(err, `Error fetching /api/signup/plan: ${planSku}`)
    }

    setFormValues(prevState => ({
      ...prevState,
      device: { os: userAgent.device?.model, type: userAgent.browser?.name },
      plan: planSku,
    }))

    setIsLoading(false)
    navigateToStep(navigation.steps.authenticate)
  }, [sku, isReady])

  const handleForgotPassword = useCallback(async () => {
    const { userId } = formValues
    try {
      const res = await fetch('/api/signup/resetPassword', {
        method: 'POST',
        body: JSON.stringify({ userId }),
      })
      const resMessage = await res.json()
      if (resMessage.status > 300) {
        alerts.error('Error Resetting Password')
      } else {
        navigateToStep(navigation.steps.resetPassword)
      }
    } catch (err) {
      sendError(err, 'Error fetching /api/signup/resetPassword')
      alerts.error('Error Resetting Password')
    }
  }, [formValues])

  useEffect(() => {
    const el = document.querySelector('html')
    if (el) el.scrollTop = 0
  }, [navigation.currentStep])

  return (
    <SignUpContext.Provider value={{
      formValues,
      setFormValues,
      navigation,
      navigateToStep,
      plan,
      setPlan,
      coupon,
      setCoupon,
      canPurchasePlan,
      setCanPurchasePlan,
      shouldSkipPlanSelect,
      isLinkedToGoogle,
      setIsLinkedToGoogle,
      setFormBoxHeight,
      setFormHolderHeight,
      formBoxHeight,
      formHolderHeight,
      order,
      setOrder,
      csrfToken,
      setCsrfToken,
      utm,
      isLoading,
      handleForgotPassword,
      bannerDescription,
    }}
    >
      {children}
    </SignUpContext.Provider>
  )
}

SignUpProvider.propTypes = {
  children: PropTypes.node,
}

export default SignUpProvider
